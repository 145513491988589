import axios from "axios";

export default {
  async getVars() {
    return axios.get(process.env.VUE_APP_BACKEND + "/api/fuzzyVars").then((response) => {
      return response.data;
    });
  },
  async getVarsTriggerable() {
    return axios.get(process.env.VUE_APP_BACKEND + "/api/fuzzyVarsTriggerable").then((response) => {
      return response.data;
    });
  },
  async addVar(variable) {
    return axios.post(process.env.VUE_APP_BACKEND + "/api/fuzzyVar", variable).then((response) => {
      return response.data;
    });
  },
  async editVar(variable) {
    return axios.post(process.env.VUE_APP_BACKEND + "/api/fuzzyVar/" + variable._id, variable).then((response) => {
      return response.data;
    });
  },
  async removeVar(variable) {
    return axios.delete(process.env.VUE_APP_BACKEND + "/api/fuzzyVar/" + variable._id).then((response) => {
      return response.data;
    });
  },
};
